import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import * as moment from "moment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoggedInUser } from "../domain/loggedin.user";
import { AuthService } from "./auth.service";
// import { Iinterval } from "../models/iinterval";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  user: LoggedInUser;

  constructor(private http: HttpClient, private _authService: AuthService) {
    this.user = _authService.getLoggedInUser();
  }

  convertDateTime(date: Date) {
    var _formattedDate = new Date(date.toString());
    return _formattedDate.toDateString();
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  myParseDate(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if (typeof value === "string" && value === "") {
      return new Date();
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  convertDate2Str(value: any, format: string = ""): string {
    // Try and parse the passed value.
    var momentDate = moment(value);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) return value;

    // Otherwise, return the date formatted as requested.
    return momentDate.format(format);
  }

  UnflattenFunc = (arr: any[]): any[] => {
    var tree:any = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = arrElem;
      mappedArr[arrElem.key]["children"] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent_id) {
          mappedArr[mappedElem["parent_id"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };

  UnflattenFunc0 = (arr: any[]): any[] => {
    var tree:any = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = arrElem;
      mappedArr[arrElem.key]["children"] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent_id) {
          mappedArr[mappedElem["parentid"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };

  UnflattenFuncPermission = (arr: any[]): any[] => {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]["children"] = [];
    }

    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          mappedArr[mappedElem["parentid"]]["children"].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };

  // Unflatten = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     node.children = [];
  //     map[node.ID] = i; // use map to look-up the parents
  //     if (node.ParentId !== null) {
  //       arr[map[node.ParentId]].children.push(node);
  //     } else {
  //       roots.push(node);
  //     }
  //   }
  //   return roots;
  // };

  // UnflattenDiagram = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     node.children = [];
  //     map[node.id_org] = i; // use map to look-up the parents
  //     if (node.parentId !== 0) {
  //       arr[map[node.parentId]].children.push(node);
  //     } else {
  //       roots.push(node);
  //     }
  //   }
  //   return roots;
  // };

  // UnflattenDept = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     //node.children = [];
  //     map[node.dept_id] = i; // use map to look-up the parents
  //     roots.push(node);
  //   }
  //   return roots;
  // };

  // UnflattenTitle = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     //node.children = [];
  //     map[node.id] = i; // use map to look-up the parents
  //     roots.push(node);
  //   }
  //   return roots;
  // };

  // Unflatten2 = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     node.children = [];
  //     map[node.ID] = i; // use map to look-up the parents
  //     if (node.ParentID !== null) {
  //       arr[map[node.ParentID]].children.push(node);
  //     } else {
  //       roots.push(node);
  //     }
  //   }
  //   return roots;
  // };

  // UnflattenDeptNew = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     node.children = [];
  //     map[node.key] = i; // use map to look-up the parents
  //     if (node.parent_id !== null) {
  //       // console.log(map[node.key], node);
  //       arr[map[node.parent_id]].children.push(node);
  //     } else {
  //       roots.push(node);
  //     }
  //   }
  //   return roots;
  // }; ///Dept

  // Unflatten3 = (arr: any[]): any[] => {
  //   let map = {};
  //   let roots: any[] = [];
  //   for (var i = 0; i < arr.length; i += 1) {
  //     let node = arr[i];
  //     node.children = [];
  //     map[node.id] = i; // use map to look-up the parents
  //     if (node.parentid !== null) {
  //       arr[map[node.parentid]].children.push(node);
  //     } else {
  //       roots.push(node);
  //     }
  //   }
  //   return roots;
  // };

  MakeSeoTitle(input: string) {
    if (input === undefined || input === "" || input.length === 0) return "";
    //Đổi chữ hoa thành chữ thường
    var slug = input.toLowerCase();

    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
    slug = slug.replace(/đ/gi, "d");
    //Xóa các ký tự đặt biệt
    slug = slug.replace(
      /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
      ""
    );
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, "-");
    slug = slug.replace(/\-\-\-\-/gi, "-");
    slug = slug.replace(/\-\-\-/gi, "-");
    slug = slug.replace(/\-\-/gi, "-");
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = "@" + slug + "@";
    slug = slug.replace(/\@\-|\-\@|\@/gi, "");

    return slug;
  }

  MakeSeoTitleNoSolid(input: string) {
    if (input === undefined || input === "") return "";
    //Đổi chữ hoa thành chữ thường
    var slug = input.toLowerCase();

    //Đổi ký tự có dấu thành không dấu
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
    slug = slug.replace(/đ/gi, "d");
    //Xóa các ký tự đặt biệt
    slug = slug.replace(
      /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
      ""
    );
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, " ");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/     /gi, " ");
    slug = slug.replace(/    /gi, " ");
    slug = slug.replace(/   /gi, " ");
    slug = slug.replace(/  /gi, " ");
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = "@" + slug + "@";
    slug = slug.replace(/\@\-|\-\@|\@/gi, "");

    return slug;
  }

  capitalizeFirstLetter(string) {
    var pieces = string.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
  }

  isValidDate(dateString) {
    var isValid = false;
    var date;

    date = new Date(dateString);

    if (Object.prototype.toString.call(date) === "[object Date]") {
      if (isNaN(date.getTime())) {
        // Date is unreal.
      } else {
        // Date is real if month and day match each other in date and string (otherwise may be shifted):
        isValid =
          date.getUTCMonth() + 1 === dateString.split("-")[1] * 1 &&
          date.getUTCDate() === dateString.split("-")[2] * 1;
      }
    } else {
      // It's not a date.
    }
    if (moment(dateString).format("YYYY").substr(0, 1) === "0") {
      isValid = false;
    }

    return isValid;
  }

  days_between(date1, date2) {
    // calculate the time difference of two dates JavaScript

    var Difference_In_Time =
      new Date(date2).getTime() - new Date(date1).getTime();
    var _numDays = Difference_In_Time / (1000 * 3600 * 24);

    return _numDays;
  }

  StringConverter(value: any) {
    if (value === null || value === undefined || typeof value === "string")
      return value;

    return value.toString();
  }
  BooleanConverter(value: any) {
    if (value === null || value === undefined || typeof value === "boolean")
      return value;

    return value.toString() === "true";
  }
  NumberConverter(value: any) {
    if (value === null || value === undefined || typeof value === "number")
      return value;

    return parseFloat(value.toString());
  }
  transform(val: number): string {
    // Format the output to display any way you want here.
    // For instance:
    if (val !== undefined && val !== null) {
      return val.toLocaleString();
    } else {
      return "";
    }
  }

  truncate(value: string, limit = 25, completeWords = false, ellipsis = "...") {
    if (completeWords) {
      limit = value.substr(0, 13).lastIndexOf(" ");
    }
    return `${value.substr(0, limit)}${ellipsis}`;
  }

  // Static member
  MakeNew(): string {
    var result: string;
    var i: string;
    var j: number;

    result = "";
    for (j = 0; j < 32; j++) {
      if (j == 8 || j == 12 || j == 16 || j == 20) result = result + "-";
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toUpperCase();
      result = result + i;
    }
    return result;
  }
  isNumber(value: string | number): boolean {
    return value != null && !isNaN(Number(value.toString()));
  }

  isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (re.test(email)) {
      // Yay! valid
      return true;
    } else {
      return false;
    }
    //return re.test(String(email).toLowerCase());
  }

  stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
    return formatedDate;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  notificationMsg(stat: any, msg: any) {
    if (stat == "OK") {
      Swal.fire("Good job!", msg, "success");
    }
    if (stat == "Error") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: msg,
      });
    }
    if (stat == "top_ok") {
      Swal.fire({
        // position: "top-end",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (stat == "top_error") {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (stat == "top_error_right") {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (stat == "top_warning") {
      Swal.fire({
        // position: "top-end",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 0,
        allowOutsideClick: false,
      });
    }
  }

  getAge(d1, d2) {
    d2 = d2 || new Date();
    var diff = d2.getTime() - d1.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  }

  // getIntervalTime(dateA: number, dateB: number): Iinterval {
  //   let intervalTime: number = Math.floor((dateA - dateB) / 1000);
  //   return this.getTime(intervalTime);
  // }

  // private getTime(time: number): Iinterval {
  //   let interval: Iinterval = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  //   interval.days = Math.floor(time / 86400);
  //   time -= interval.days * 86400;
  //   interval.hours = Math.floor(time / 3600) % 24;
  //   time -= interval.hours * 3600;
  //   interval.minutes = Math.floor(time / 60) % 60;
  //   time -= interval.minutes * 60;
  //   interval.seconds = time % 60;
  //   return interval;
  // }

  // validIntervalCountdown(timeToGo) {
  //   return timeToGo - Date.now() >= 0 ? true : false;
  // }

  // getCountDown(timeToGo: number): Observable<Iinterval> {
  //   return interval(1000).pipe(
  //     take(0),
  //     map(interval => this.getIntervalTime(timeToGo, Date.now()))
  //   );
  // }
  async transformFile(src: string): Promise<string> {
    const token = this.user.access_token;
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    try {
      const imageBlob = await this.http
        .get(src, { headers, responseType: "blob" })
        .toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      return "assets/fallback.png";
    }
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  convertImageToDataURL(imgSrc) {
    var img = new Image();
    img.src = imgSrc;
    img.crossOrigin = "Anonymous";
    console.log(img);

    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    console.log("KQ", dataURL);

    canvas = null;
    return dataURL;
  }
}
