export class SystemConstants {
  public static CURRENT_USER = "CURRENT_USER";
  public static REMOTE_CURRENT_USER = "REMOTE_CURRENT_USER";

  // public static BASE_API_LOGIN = "https://localhost:6001/";//connect/token/ && connect/userinfo
  // public static BASE_API_IDP = "https://localhost:6001/api/";
  // public static BASE_API_IDP_IMG = "https://localhost:6001/Uploads/Avatars/";
  // public static BASE_API_PROJECT = "http://localhost:5020/api/";
  // public static BASE_API_PROJECT_URL = "http://localhost:5020/UploadFiles/";
  public static BASE_API_LOGSYSTEM = "https://localhost:5007/api/";
  // public static BASE_URL_PORTAL = "https://localhost:4443/api/";

  // public static BASE_API_LOGIN = "https://10.120.2.44:6020/"; //connect/token/ && connect/userinfo
  // public static BASE_API_IDP = "https://10.120.2.44:6020/api/";
  // public static BASE_API_IDP_IMG = "https://10.120.2.44:6020/Uploads/Avatars/";
  // public static BASE_API_CRM = "https://10.120.2.44:6020/api/";
  // public static BASE_API_PROJECT = "https://10.120.2.44:6006/api/";
  // public static BASE_API_PROJECT_URL = "https://10.120.2.44:6006/UploadFiles/";
  ///FOR DEV
  // public static BASE_URL = "http://localhost:4200";
  // // // public static BASE_URL = "http://10.120.6.133:4200";
  // // public static BASE_URL = "http://192.168.1.8:4200";
  // public static BASE_API_AI = "https://localhost:7050/api/";
  // public static BASE_API_AI_IMG = "https://localhost:7050/UploadFiles/";
  // // public static BASE_API_ABR = "https://localhost:6009/api/";
  // // public static BASE_API_QR_LOGIN = "http://192.168.1.5:4201/"
  // public static BASE_API_CRM = "https://localhost:6005/api/"//"https://localhost:6002/api/";
  // public static BASE_API_CRM_DOWNLOAD = "https://localhost:6005/UploadFiles/"//"https://localhost:6002/api/";
  // public static BASE_API_CRM_SK = "https://localhost:6005/api/wiki/callSignalrWikiPage"

  public static BASE_API_HIS = "https://localhost:7075/api/";
  public static BASE_API_HIS_IMG = "https://localhost:7075/UploadFiles/";
  public static BASE_API_QR = "http://192.168.1.9:4201/";

  ////UAT
  // public static BASE_URL = "https://uat-itsm.medicalsaigon.com:5010";//"https://192.168.80.243:443";//"https://10.120.2.130:443";//""https://192.168.100.11:443"";
  // public static BASE_API_AI = "https://uatapi-portal5002.medicalsaigon.com:5002/api/";
  // public static BASE_API_AI_IMG = "https://uatapi-portal5002.medicalsaigon.com:5002/UploadFiles/";
  // public static BASE_API_CRM = "https://uatapi-portal5003.medicalsaigon.com:5003/api/";//"https://localhost:6002/api/";
  // public static BASE_API_CRM_DOWNLOAD = "https://uatapi-portal5003.medicalsaigon.com:5003/UploadFiles/"//"https://localhost:6002/api/";
  // public static BASE_API_CRM_SK = "https://uatapi-portal5003.medicalsaigon.com:5003/api/wiki/callSignalrWikiPage"//"https://localhost:6002/api/";
  // public static BASE_API_QR_LOGIN = "https://authenqr.medicalsaigon.com/"
  // public static BASE_API_ABR = "https://uatapi-portal5004.medicalsaigon.com:5004/api/";
  //API AUTH PRODUCT UAT
  // public static BASE_API_LOGIN = "https://uatapi-gateway.medicalsaigon.com:5001/";//connect/token/ && connect/userinfo
  // public static BASE_API_IDP = "https://uatapi-gateway.medicalsaigon.com:5001/api/";
  // public static BASE_API_IDP_IMG = "https://uatapi-gateway.medicalsaigon.com:5001/Uploads/Avatars/";

  ///RUN PRODUCT DOMAIN
  public static BASE_URL = "https://itsm.medicalsaigon.com";//"https://192.168.80.243:443";//"https://10.120.2.130:443";//""https://192.168.100.11:443"";
  public static BASE_API_AI = "https://api-portal7050.medicalsaigon.com:7050/api/";
  public static BASE_API_AI_IMG = "https://api-portal7050.medicalsaigon.com:7050/UploadFiles/";
  public static BASE_API_CRM = "https://api-portal6005.medicalsaigon.com:6005/api/";//"https://localhost:6002/api/";
  public static BASE_API_CRM_DOWNLOAD = "https://api-portal6005.medicalsaigon.com:6005/UploadFiles/"//"https://localhost:6002/api/";
  public static BASE_API_CRM_SK = "https://api-portal6005.medicalsaigon.com:6005/api/wiki/callSignalrWikiPage"//"https://localhost:6002/api/";
  public static BASE_API_QR_LOGIN = "https://authenqr.medicalsaigon.com/"
  public static BASE_API_ABR = "https://api-portal6009.medicalsaigon.com:6009/api/";

  // public static BASE_API_HIS = "https://api.matgiaominh.com/api/";
  // public static BASE_API_HIS_IMG = "https://api.matgiaominh.com/UploadFiles/";

  // public static BASE_API_LOGIN = "https://auth.matgiaominh.com:6001/";//connect/token/ && connect/userinfo
  // public static BASE_API_IDP = "https://auth.matgiaominh.com:6001/api/";
  // public static BASE_API_IDP_IMG = "https://auth.matgiaominh.com:6001/Uploads/Avatars/";

  //API AUTH PRODUCT
  public static BASE_API_LOGIN = "https://api-gateway.medicalsaigon.com:6001/";//connect/token/ && connect/userinfo
  public static BASE_API_IDP = "https://api-gateway.medicalsaigon.com:6001/api/";
  public static BASE_API_IDP_IMG = "https://api-gateway.medicalsaigon.com:6001/Uploads/Avatars/";
  public static BASE_API_PROJECT = "https://api-nps.medicalsaigon.com/api/";
  public static BASE_API_PROJECT_URL = "https://api-nps.medicalsaigon.com/UploadFiles/";

  public static BASE_URL_EMAIL = "https://email-gateway.medicalsaigon.com/api/";//"https://localhost:7249/api/";
  public static BASE_URL_COMPL = "https://api-erm.medicalsaigon.com/api/";
}
