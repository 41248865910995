export class LoggedInUser {
  constructor(
    access_token: string,
    refresh_token: string,
    first_name: string,
    last_name: string,
    fullname:string,
    email: string,
    id: any,
    avatar: string,
    status: number,
    roles: any,
    permissions: any,
    lang: string,
    short_code: string,
    username: string,
    ipaddress: string,
    department: any
  ) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.first_name = first_name;
    this.last_name = last_name;
    this.fullname = fullname;
    this.email = email;
    this.id = id;
    this.avatar = avatar;
    this.status = status;
    this.roles = roles;
    this.permissions = permissions;
    this.lang = lang;
    this.short_code = short_code;
    this.username = username;
    this.ipaddress = ipaddress;
    this.department = department;
  }
  public id: string;
  public access_token: string;
  public refresh_token: string;
  public first_name: string;
  public last_name: string;
  public fullname:string;
  public email: string;
  public avatar: string;
  public status: number;
  public roles: any;
  public permissions: any;
  public lang: string;
  public short_code: string;
  public username: string;
  public ipaddress: string;
  public department: any;
}
