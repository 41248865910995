import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SystemConstants } from "../common/system.constants";
import { LoggedInUser } from "../domain/loggedin.user";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class RestApiService {
  // Define API
  protected apiURL = SystemConstants.BASE_API_LOGIN;

  constructor(private http: HttpClient) { }

  /*========================================
      CRUD Methods for consuming RESTful API
    =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" })
  };
  //{ "Content-Type": "application/x-www-form-urlencoded", "access-control-allow-origin": true }

  getEndPoints(url) {
    return `${this.apiURL}${url}`;
  }

  post(url, params: any, ipaddress) {
    //console.log(url,params);
    const apiUrl = this.getEndPoints(url);
    // console.log(apiUrl);
    // console.log('Params', new URLSearchParams(Object.entries(params)).toString());
    return this.http.post(apiUrl, new URLSearchParams(Object.entries(params)).toString(), this.httpOptions).pipe(
      tap(res => {
        //localStorage.removeItem(SystemConstants.CURRENT_USER);
        // console.log("Inside", res);
        //connect/userinfo
        //return this.getUserInfo('connect/userinfo', res["access_token"]);
      })
    );
  }

  getUserInfo(url, params: any) {
    let _paramsHeader = {
      headers: new HttpHeaders({ "Authorization": "Bearer " + params })
    };
    const apiUrl = this.getEndPoints(url);
    return this.http.get(apiUrl, _paramsHeader).pipe(tap(
      res => {
        // console.log('Result', res);
        
      })
    );
  }

  // get(url) {
  //   const apiUrl = this.getEndPoints(url);
  //   return this.http.get(apiUrl);
  // }

  // upload(url: string, fileToUpload: File, headers = {}) {
  //   const formData: FormData = new FormData();
  //   formData.append(fileToUpload.name, fileToUpload, fileToUpload.name);
  //   const apiUrl = this.getEndPoints(url);
  //   return this.http.post(apiUrl, formData, headers);
  // }
}
