import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataService } from "./data.service";
import { SystemConstants } from "../common/system.constants";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  public responseData: any;
  protected apiURL = SystemConstants.BASE_API_IDP;

  constructor(
    private _dataService: DataService,
    private _authService: AuthService,
    private http: HttpClient
  ) {}

  uploadFile(uri: string, params:any, files: File[]): Observable<Object> {
    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization":"Bearer "+ this._authService.getLoggedInUser().access_token,
    });
    let fileToUpload = <File>files[0];
    let formData = new FormData();
    formData.append('Id', params.Id);
    formData.append('Caption', params.Caption);
    formData.append('Folder', params.Folder);
    formData.append('Short_Code', params.Short_Code);
    formData.append('ImageFile', fileToUpload);
    console.log('Check avatar',formData);
    return this.http.post(this.apiURL + uri, formData, { headers: reqHeader });
  }

  uploadFileNew(uri: string, form:FormData) {
    var reqHeader = new HttpHeaders({
      // "Content-Type": "application/json",
      "Authorization":"Bearer "+ this._authService.getLoggedInUser().access_token,
    });
    return this.http.post(this.apiURL + uri, form, { headers: reqHeader });
  }

  uploadFileMulti(uri: string, files: any[]): Observable<Object> {
    var reqHeader = new HttpHeaders({
      //"Content-Type": "application/json",
      "Authorization":"Bearer "+ this._authService.getLoggedInUser().access_token,
    });
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    console.log(formData);
    return this.http.post(this.apiURL + uri, formData, { headers: reqHeader });
  }
}
