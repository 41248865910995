import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { SystemConstants } from "../common/system.constants";
import { UrlConstants } from "../common/url.contants";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
    //console.log('Run')
  }
  canActivate(
    activateRoute: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ) {
    if (localStorage.getItem(SystemConstants.CURRENT_USER)) {
      return true;
    } else {
      this.router.navigate([UrlConstants.LOGIN], {
        queryParams: {
          returnUrl: routerState.url,
        },
      });
      return false;
    }
    // const user = this.authService.userValue;
    //     if (user) {
    //         // logged in so return true
    //         //console.log('Logged in',user)
    //         return true;
    //     } else {
    //         // not logged in so redirect to login page with the return url
    //         this.router.navigate(['/auth/login'], { queryParams: { returnUrl: routerState.url } });
    //         return false;
    //     }
  }
}
