import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { User } from "../models/user";
import { Router } from "@angular/router";
import { SystemConstants } from "../common/system.constants";
import { LoggedInUser } from "../domain/loggedin.user";
import { RestApiService } from "./rest-api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public showLoader: boolean = false;
  basePath = SystemConstants.BASE_API_IDP;
  basePathLogin = SystemConstants.BASE_API_LOGIN;
  httpOptions = new HttpHeaders().set("Content-Type", "application/json");

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private http: RestApiService
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();
  }

  //for jwt login
  public login(
    user:any,
    ipaddress: string
  ) {
    //console.log(username,password);
    return this.http.post("connect/token", user,ipaddress);
  }

  // setUser(resp: LoginResponse) {
  //   localStorage.setItem("email", resp.email);
  //   localStorage.setItem("username", resp.username);
  //   localStorage.setItem("access_token", resp.access_token);
  //   this.router.navigate(["/dashboard"]);
  // }

  public getUserInfo(token) {
    return this.http.getUserInfo("connect/userinfo", token);
  }

  // Checking if token is set
  isLoggedIn() {
    return localStorage.getItem("access_token") != null;
  }

  ///////END LOGIN JWT

  isUserAuthenticated(): boolean {
    // let user = localStorage.getItem(SystemConstants.CURRENT_USER);
    let user = localStorage.getItem(SystemConstants.CURRENT_USER);
    if (user != null) {
      return true;
    } else return false;
  }

  getLoggedInUser(): LoggedInUser {
    let user: LoggedInUser;
    if (this.isUserAuthenticated()) {
      var userData = JSON.parse(
        localStorage.getItem(SystemConstants.CURRENT_USER)
      );
      //console.log(userData);
      user = new LoggedInUser(
        userData.access_token,
        userData.refresh_token,
        userData.first_name,
        userData.last_name,
        userData.full_name,
        userData.email,
        userData.id,
        userData.avatar,
        userData.status,
        userData.roles,
        userData.permissions,
        userData.lang,
        userData.short_code,
        userData.username,
        userData.ipaddress,
        userData.department
      );
    } else user = null;
    return user;
  }

  checkAccess(functionId: string) {
    var user = this.getLoggedInUser();
    var result: boolean = false;
    var permission: any[] = user.permissions; //JSON.parse();
    var roles: any[] = user.roles; //JSON.parse();
    var hasPermission: number = permission.findIndex(
      (x) => x.FunctionId == functionId && x.CanRead == true
    );
    //console.log('Check Access ',functionId, hasPermission, roles);
    if (hasPermission != -1 || roles.findIndex((x) => x == "Admin") != -1) {
      return true;
    } else if (roles.findIndex((x) => x.id == permission[0].RoleId) != -1) {
      return true;
    } else {
      return false;
    }
  }

  checkAccessRole(roleId: string) {
    //console.log(roleId);
    var user = this.getLoggedInUser();
    var result: boolean = false;
    var roles: any[] = user.roles; //JSON.parse();
    if (roles.findIndex((x) => x == "Admin") != -1) {
      return true;
    } else {
      return false;
    }
  }

  hasPermission(functionId: string, action: string): boolean {
    var user = this.getLoggedInUser();
    var result: boolean = false;
    if (user.permissions === null) {
      window.location.href = "";
    }
    var permission: any[] = user.permissions; //JSON.parse();
    var roles: any[] = user.roles; //JSON.parse();
    // console.log(functionId + " " + action, "role", roles);
    switch (action) {
      case "read":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanRead == true
        );
        //console.log(hasPermission);
        if (hasPermission != -1 || roles.findIndex((x) => x == "Admin") != -1)
          result = true;
        break;
      case "create":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanCreate == true
        );
        //console.log(hasPermission);
        if (hasPermission != -1 || roles.findIndex((x) => x == "Admin") != -1)
          result = true;
        break;
      case "update":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanUpdate == true
        );
        if (hasPermission != -1 || roles.findIndex((x) => x == "Admin") != -1)
          result = true;
        // console.log("KQ", result);
        break;
      case "delete":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanDelete == true
        );
        if (
          hasPermission != -1 ||
          roles.findIndex((x) => x == "Admin") != -1
        )
          result = true;
        break;
      case "print":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanPrint == true
        );
        if (
          hasPermission != -1 ||
          roles.findIndex((x) => x == "Admin") != -1
        )
          result = true;
        break;
      case "hide":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanHidden == true
        );
        if (
          hasPermission != -1 ||
          roles.findIndex((x) => x == "Admin") != -1
        )
          result = true;
        break;
      case "report":
        var hasPermission: number = permission.findIndex(
          (x) => x.FunctionId == functionId && x.CanReport == true
        );
        if (
          hasPermission != -1 ||
          roles.findIndex((x) => x == "Admin") != -1
        )
          result = true;
        break;
    }
    return result;
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Cannot connect to server!!!");
  }

  logout() {
    localStorage.removeItem(SystemConstants.CURRENT_USER);
  }

  login2(username: string, password: string, rememberme: boolean) {
    return this.httpClient
      .post<any>(
        `${this.basePath}users/loginv3`,
        { username, password, rememberme },
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          // console.log("Here", user);
          let _data: any = {};
          _data.id = user["ResultObj"]["Id"];
          _data.first_name = user["ResultObj"]["FirstName"];
          _data.last_name = user["ResultObj"]["LastName"];
          _data.fullname = null; //res["ResultObj"]["fullname"];
          _data.email = user["ResultObj"]["Email"];
          _data.access_token = user["ResultObj"]["token"];
          // _data.refresh_token = res["ResultObj"]["refreshToken"]==undefined?null:res["ResultObj"]["refreshToken"];
          _data.avatar =
            user["ResultObj"]["Employee"]["Avatar"] == undefined
              ? null
              : user["ResultObj"]["Employee"]["Avatar"];
          _data.status =
            user["ResultObj"]["Employee"]["WorkStatus"] == undefined
              ? null
              : user["ResultObj"]["Employee"]["WorkStatus"];
          _data.roles =
            user["ResultObj"]["Roles"] == undefined
              ? []
              : user["ResultObj"]["Roles"];
          _data.permissions =
            user["ResultObj"]["Permissions"] == undefined
              ? []
              : user["ResultObj"]["Permissions"];
          _data.lang = user["ResultObj"]["Lang"];
          _data.short_code =
            user["ResultObj"]["Employee"]["Short_Code"] == undefined
              ? null
              : user["ResultObj"]["Employee"]["Short_Code"];
          _data.username = user["ResultObj"]["UserName"];
          _data.department =
            user["ResultObj"]["Department"] == undefined
              ? []
              : user["ResultObj"]["Department"];
          _data.ipaddress = null;
          let user0: LoggedInUser = _data; //.json();
          // console.log('After',user);
          //user.expires = new Date(res[".expires"]);
          // console.log(res['.expires']);
          // console.log(new Date(res['.expires']));
          // console.log(new Date());
          if (user0 && user0.access_token) {
            // && user.status == 1
            localStorage.removeItem(SystemConstants.CURRENT_USER);
            localStorage.setItem(
              SystemConstants.CURRENT_USER,
              JSON.stringify(user0)
            );
          }
          //localStorage.setItem('user', JSON.stringify(_data));
          this.userSubject.next(_data);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  logout2() {
    this.httpClient
      .post<any>(
        `${this.basePath}users/revoke-token`,
        {},
        { withCredentials: true }
      )
      .subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(["/auth/login"]);
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  // helper methods
  refreshToken() {
    return this.httpClient
      .post<any>(
        `${this.basePath}users/refresh-token`,
        {},
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(
      atob(this.userValue.access_token.split(".")[1])
    );
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;

    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
