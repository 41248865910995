import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },  
  { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) },
  { path: "outside", loadChildren: () => import(`./outside/outside.module`).then((m) => m.OutsideModule) },
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },
  { path: "layout", loadChildren: () => import(`./all-modules/all-modules.module`).then((m) => m.AllModulesModule),canActivate: [AuthGuard],},
  { path: '**', redirectTo: '/login'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
