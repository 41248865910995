import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Bootstrap DataTable
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AuthService } from './core/services/auth.service';
import { DataService } from './core/services/data.service';
import { NotifyService } from './core/services/notify.service';
import { UploadService } from './core/services/upload.service';
import { ExcelService } from './core/services/excel.service';
import { UtilityService } from './core/services/utility.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NZ_I18N , en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgZorroAntdModule } from './ng-zorro-antd.module';

registerLocaleData(en);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    DataTablesModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }
    ),
    NgZorroAntdModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    AuthService,
    DataService,
    NotifyService,
    UploadService,
    ExcelService,
    UtilityService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
